import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

function NoUnitsIcon(props: IconButtonProps) {
  const {t} = useTranslation();

  return (
    <Tooltip title={t('no-units.toolbar')}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          width: 36,
          height: 36,
          animation: 'pulse 1.5s infinite',
          '@keyframes pulse': {
            '0%': {transform: 'scale(1)'},
            '50%': {transform: 'scale(1.2)'},
            '100%': {transform: 'scale(1)'},
          },
        }}
      >
        <IconButton color="warning" {...props}>
          <WarningIcon fontSize="medium" color="warning" />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

export const NoUnitsIconComponent = memo(NoUnitsIcon);
