import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {Field, FieldProps, Form, Formik} from 'formik';
import React, {memo, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {FieldWrapperComponent} from '../../forms/patient/shared/field-wrapper.component';
import {AccessRequest} from '../../interfaces/entities/access-request.interface';
import {VILMER_LOGO_PATH} from '../../utils/constants';
import {BackdropLoadingComponent} from '../loading/backdrop-loading.component';
import {DialogComponent, DialogProps} from './dialog.component';

const USER_TYPE_OPTIONS = ['health-care-staff', 'next-of-kin', 'volunteer-or-everyday-friend', 'other'];

export interface NoUnitsWarningDialogProps extends DialogProps {
  checkAccessRequest?: () => Promise<boolean>;
  onSubmit?: (data: Partial<AccessRequest>) => Promise<void>;
  alreadyCreated?: boolean;
  loading?: boolean;
}

function NoUnitsWarningDialog({
  checkAccessRequest,
  onSubmit,
  alreadyCreated,
  loading = false,
  ...props
}: NoUnitsWarningDialogProps) {
  const {t} = useTranslation();

  const handleSubmit = useCallback(
    (data: Partial<AccessRequest>) => {
      if (onSubmit) {
        return onSubmit(data);
      }
    },
    [onSubmit]
  );

  useEffect(() => {
    if (props.isOpen && checkAccessRequest) {
      checkAccessRequest();
    }
  }, [checkAccessRequest, props.isOpen]);

  const userTypeOptions = useMemo(() => {
    return USER_TYPE_OPTIONS.map(value => ({
      value,
      label: t(`no-units.modal.accessRequest.userType-${value}.title`, value),
    }));
  }, [t]);

  return (
    <DialogComponent
      maxWidth={'xs'}
      {...props}
      title={
        <img
          src={VILMER_LOGO_PATH}
          alt="VILMER Logo"
          style={{
            height: '50px',
          }}
        />
      }
      content={
        !alreadyCreated ? (
          <Formik<Partial<AccessRequest>>
            initialValues={{
              userType: undefined,
              messageMunicipality: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object<Record<keyof AccessRequest, Yup.AnySchema>>().shape({
              userType: Yup.string().required('required-field-not-filled.error'),
              messageMunicipality: Yup.string().required('required-field-not-filled.error'),
              messageInstitution: Yup.string().nullable().optional(),
            })}
          >
            {({handleSubmit: formHandleSubmit}) => (
              <Form>
                <Typography variant="subtitle2" fontWeight={500}>
                  {t('no-units.modal.solution.title')}
                </Typography>

                <Typography variant="subtitle2" pt={1} fontWeight={400}>
                  {t('no-units.modal.solution.text')}
                </Typography>

                <Typography variant="subtitle1" pt={1} fontWeight={400}>
                  {t('no-units.modal.accessRequest.userType.title')}
                </Typography>
                <Field name={'userType'}>
                  {(props: FieldProps) => {
                    const error = props.meta.touched && !!props.meta.error;
                    return (
                      <FieldWrapperComponent {...props}>
                        <Select
                          fullWidth
                          variant="filled"
                          sx={theme => ({
                            '& [role=combobox]': {
                              py: 1.25,
                            },
                            borderRadius: '8px',
                            py: '0px !important',
                            minHeight: 'auto',
                            ...(error
                              ? {
                                  borderStyle: 'solid',
                                  borderWidth: '1px',
                                  borderColor: theme.palette.error.main,
                                }
                              : {}),
                          })}
                          MenuProps={{
                            sx: theme => ({
                              zIndex: theme.zIndex.tooltip + 1,
                            }),
                          }}
                          disableUnderline
                          {...props.field}
                          error={!!error}
                        >
                          {userTypeOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FieldWrapperComponent>
                    );
                  }}
                </Field>

                <Typography variant="subtitle1" pt={1.5} fontWeight={400}>
                  {t('no-units.modal.accessRequest.messageMunicipality.title')}
                </Typography>

                <Field name={'messageMunicipality'}>
                  {(props: FieldProps) => {
                    const error = props.meta.touched && !!props.meta.error;
                    return (
                      <FieldWrapperComponent {...props}>
                        <TextField
                          sx={{
                            '& input': {
                              py: 1.25,
                            },
                          }}
                          InputProps={{
                            disableUnderline: true,
                            sx: theme => ({
                              borderRadius: '8px',

                              py: '0px !important',
                              minHeight: 'auto',
                              ...(error
                                ? {
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderColor: theme.palette.error.main,
                                  }
                                : {}),
                            }),
                          }}
                          variant="filled"
                          size="small"
                          fullWidth
                          error={props.meta.touched && !!props.meta.error}
                          {...props.field}
                        />
                      </FieldWrapperComponent>
                    );
                  }}
                </Field>

                <Typography variant="subtitle1" pt={1} fontWeight={400}>
                  {t('no-units.modal.accessRequest.messageInstitution.title')}
                </Typography>

                <Field name={'messageInstitution'}>
                  {(props: FieldProps) => {
                    return (
                      <FieldWrapperComponent {...props}>
                        <TextField
                          sx={{
                            '& input': {
                              py: 1.25,
                            },
                          }}
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              borderRadius: '8px',
                              py: '0px !important',
                              minHeight: 'auto',
                            },
                          }}
                          variant="filled"
                          size="small"
                          fullWidth
                          error={props.meta.touched && !!props.meta.error}
                          {...props.field}
                        />
                      </FieldWrapperComponent>
                    );
                  }}
                </Field>
                <DialogActions sx={{pt: 4, mb: -2}}>
                  {/* <CancelButtonComponent onClick={props.onClose as () => void} /> */}
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: '50px',
                      textTransform: 'none',
                    }}
                    onClick={() => formHandleSubmit()}
                  >
                    {t('no-units.modal.accessRequest.button')}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                pt: 4,
              }}
            >
              <SyncIcon color={'warning'} fontSize={'large'} sx={{transform: 'scale(2)'}} />
              <Typography pt={1} variant="subtitle1" textAlign={'center'}>
                {t('access-request-already-created.error')}
              </Typography>
            </Stack>
            <DialogActions sx={{pt: 4, mb: -2}}>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  borderRadius: '50px',
                  textTransform: 'none',
                }}
                onClick={props.onClose}
              >
                {t('close.button')}
              </Button>
            </DialogActions>
          </>
        )
      }
    >
      <BackdropLoadingComponent
        sx={{
          position: 'absolute',
        }}
        open={loading}
      />
    </DialogComponent>
  );
}

export const NoUnitsWarningDialogComponent = memo(NoUnitsWarningDialog);
