import Cookies from 'js-cookie';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {NoUnitsWarningDialogComponent} from '../components/dialog/no-units-warning.component';
import {HeaderComponent, HeaderProps} from '../components/header/header.component';
import {useCountryCode} from '../hooks/use-country-code';
import {useStores} from '../hooks/use-stores';
import {AccessRequest} from '../interfaces/entities/access-request.interface';
import {AUTH_TOKEN_DOMAIN, LOCALES} from '../utils/constants';

const MODAL_NO_UNITS_WARNING_COOKIE_KEY = 'no-units-warning';

const EXPIRED_CLOSED_NO_UNITS_WARNING = 5 * 60 * 1000;

function Header(props: Omit<HeaderProps, 'onClickLogin'>) {
  const {i18n, t} = useTranslation();

  const {enqueueSnackbar} = useSnackbar();

  const {authStore, usersStore} = useStores();

  const {loading: loadingCountry, countryCode} = useCountryCode();

  const [openNoUnitsWarning, setOpenNoUnitsWarning] = useState(false);

  useEffect(() => {
    authStore.fetchMe();
  }, [authStore]);

  const logged = !!authStore.me;

  useEffect(() => {
    if (authStore.loading !== false) {
      return;
    }

    if (!authStore.me?.language && loadingCountry === false && countryCode?.toLowerCase() === 'se') {
      i18n.changeLanguage(LOCALES.sv);
    } else if (authStore.me?.language && i18n.language !== authStore.me?.language) {
      i18n.changeLanguage(authStore.me.language);
    }
  }, [authStore.me, authStore.loading, loadingCountry, countryCode, i18n.language, i18n]);

  useEffect(() => {
    usersStore.fetchUserUnits();
  }, [usersStore]);

  const handleClickNoUnits = useCallback(() => {
    setOpenNoUnitsWarning(true);
  }, []);

  const showWithoutUnitsWarning = useMemo(() => {
    return usersStore.userUnits && usersStore.userUnits.length === 0;
  }, [usersStore.userUnits]);

  useEffect(() => {
    if (showWithoutUnitsWarning) {
      let closedAt;
      try {
        const closedAtStr = Cookies.get(MODAL_NO_UNITS_WARNING_COOKIE_KEY);
        if (closedAtStr) {
          closedAt = new Date(closedAtStr);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        closedAt = undefined;
      }

      if (
        !closedAt ||
        isNaN(closedAt.getTime()) ||
        closedAt.getTime() + EXPIRED_CLOSED_NO_UNITS_WARNING < new Date().getTime()
      ) {
        handleClickNoUnits();
      }
    }
  }, [handleClickNoUnits, showWithoutUnitsWarning]);

  const onChangeLanguage = useCallback(
    (language: LOCALES) => {
      if (logged) {
        usersStore.updateLanguage(language);
      }
    },
    [logged, usersStore]
  );

  const handleCloseNoUnits = useCallback(() => {
    setOpenNoUnitsWarning(false);
    Cookies.set(MODAL_NO_UNITS_WARNING_COOKIE_KEY, new Date().toISOString(), {domain: AUTH_TOKEN_DOMAIN});
  }, []);

  const checkAccessRequest = useCallback(async () => {
    const data = await usersStore.checkAccessRequest();

    if (!data) {
      return true;
    }

    return data.result;
  }, [usersStore]);

  const handleCreateAccessRequest = useCallback(
    async (data: Partial<AccessRequest>) => {
      setOpenNoUnitsWarning(false);
      await usersStore.createAccessRequest(data);
      enqueueSnackbar(t('access-request-created.text'), {variant: 'success'});
    },
    [enqueueSnackbar, t, usersStore]
  );

  return (
    <>
      <HeaderComponent
        {...props}
        loadingLogin={authStore.loading}
        logged={logged}
        onChangeLanguage={onChangeLanguage}
        showWithoutUnitsWarning={showWithoutUnitsWarning}
        onClickNoUnitsIcon={handleClickNoUnits}
      />
      <NoUnitsWarningDialogComponent
        isOpen={openNoUnitsWarning}
        onClose={handleCloseNoUnits}
        checkAccessRequest={checkAccessRequest}
        alreadyCreated={usersStore.alreadyCreatedAccessRequest.data?.result === false}
        loading={usersStore.loadingUserUnits || usersStore.alreadyCreatedAccessRequest.loading}
        onSubmit={handleCreateAccessRequest}
      />
    </>
  );
}

export const HeaderContainer = observer(Header);
