import {AxiosInstance} from 'axios';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {AccessRequest} from '../interfaces/entities/access-request.interface';
import {Unit} from '../interfaces/entities/unit.interface';
import {GetManyResponse} from '../interfaces/get-many-response.interface';
import {User, USER_ROLES} from '../interfaces/user.interface';
import {ADMIN_API_URL, LOCALES} from '../utils/constants';
import {AuthStore} from './auth.store';

const FETCH_NURSES_BY_INSTITUTION_ID_LIMIT = 100;
const FETCH_NURSES_BY_DEPARTMENT_ID_LIMIT = 100;
const FETCH_USERS_BY_UNIT_ID_LIMIT = 100;
const MAX_CACHE_TIME = 2 * 60 * 1000;

export class UsersStore {
  allNursesByInstitutionId: Record<Unit['id'], User[]> = {};
  allNursesByDepartmentId: Record<Unit['id'], User[]> = {};
  allDeactivatedUsersByUnitId: Record<Unit['id'], User[]> = {};
  loadingAllNursesByInstitutionId: Record<Unit['id'], boolean> = {};
  loadingAllNursesByDepartmentId: Record<Unit['id'], boolean> = {};
  loadingAllDeactivatedUsersByUnitId: Record<Unit['id'], boolean> = {};
  userUnits?: Record<string, any>[];
  loadingUserUnits?: boolean;
  errorFetchUserUnits?: boolean;
  fetchUserUnitsLastTimeAt?: Date;
  alreadyCreatedAccessRequest: {
    loading?: boolean;
    data?: {result: boolean};
    error?: boolean;
  } = {};

  constructor(
    protected readonly api: AxiosInstance,
    private readonly authSore: AuthStore,
    private readonly maxCacheTime = MAX_CACHE_TIME
  ) {
    makeObservable(this, {
      allNursesByInstitutionId: observable,
      loadingAllNursesByInstitutionId: observable,
      allNursesByDepartmentId: observable,
      allDeactivatedUsersByUnitId: observable,
      loadingAllNursesByDepartmentId: observable,
      loadingAllDeactivatedUsersByUnitId: observable,
      userUnits: observable,
      loadingUserUnits: observable,
      errorFetchUserUnits: observable,
      fetchUserUnitsLastTimeAt: observable,
      alreadyCreatedAccessRequest: observable,
      updateLanguage: action,
      fetchAllNursesFromInstitution: action,
      fetchAllNursesFromDepartment: action,
      fetchAllDeactivatedUsersFromUnit: action,
      fetchUserUnits: action,
      checkAccessRequest: action,
    });
  }

  async updateLanguage(language: LOCALES) {
    try {
      const url = new URL(ADMIN_API_URL);
      url.pathname = 'user';

      const {data} = await this.api.put<User>(url.toString(), {
        language,
      });

      runInAction(() => {
        if (this.authSore.me) {
          this.authSore.me = {...this.authSore.me, language: data.language};
        }
      });

      // eslint-disable-next-line no-empty
    } catch (_err) {}
  }

  async updateRole(role: USER_ROLES) {
    try {
      const {data} = await this.api.patch<User>('/users/me', {
        role,
      });
      runInAction(() => {
        this.authSore.me = data;
      });
      // eslint-disable-next-line no-empty
    } catch (_err) {}
  }

  async fetchAllNursesFromInstitution(institutionId: string, force = false) {
    if (!force && this.allNursesByInstitutionId[institutionId]) {
      return;
    }

    if (this.loadingAllNursesByInstitutionId[institutionId]) {
      return;
    }

    runInAction(() => {
      this.loadingAllNursesByInstitutionId[institutionId] = true;
    });
    let more = true;
    let page = 1;
    const arr: User[] = [];
    while (more) {
      const searchParams = new URLSearchParams();
      searchParams.append('page', '' + page);
      searchParams.append('limit', '' + FETCH_NURSES_BY_INSTITUTION_ID_LIMIT);
      searchParams.append('sort', `firstName,ASC`);

      try {
        const {
          data: {data, pageCount},
        } = await this.api.get<GetManyResponse<User>>(
          `institutions/${institutionId}/nurses?${searchParams.toString()}`
        );
        arr.push(...data);
        more = (pageCount || 0) > page;
        page += 1;
      } catch (err) {
        console.error(err);
        break;
      }

      runInAction(() => {
        this.allNursesByInstitutionId[institutionId] = arr;
        this.loadingAllNursesByInstitutionId[institutionId] = false;
      });
    }
  }

  async fetchAllNursesFromDepartment(departmentId: string, force = false) {
    if (!force && this.allNursesByDepartmentId[departmentId]) {
      return;
    }

    if (this.loadingAllNursesByDepartmentId[departmentId]) {
      return;
    }

    runInAction(() => {
      this.loadingAllNursesByDepartmentId[departmentId] = true;
    });
    let more = true;
    let page = 1;
    const arr: User[] = [];
    while (more) {
      const searchParams = new URLSearchParams();
      searchParams.append('page', '' + page);
      searchParams.append('limit', '' + FETCH_NURSES_BY_DEPARTMENT_ID_LIMIT);
      searchParams.append('sort', `firstName,ASC`);

      try {
        const {
          data: {data, pageCount},
        } = await this.api.get<GetManyResponse<User>>(`departments/${departmentId}/nurses?${searchParams.toString()}`);
        arr.push(...data);
        more = (pageCount || 0) > page;
        page += 1;
      } catch (err) {
        console.error(err);
        break;
      }

      runInAction(() => {
        this.allNursesByDepartmentId[departmentId] = arr;
        this.loadingAllNursesByDepartmentId[departmentId] = false;
      });
    }
  }

  async fetchAllDeactivatedUsersFromUnit(unitId: string, force = false) {
    if (!force && this.allDeactivatedUsersByUnitId[unitId]) {
      return;
    }

    if (this.loadingAllDeactivatedUsersByUnitId[unitId]) {
      return;
    }

    runInAction(() => {
      this.loadingAllDeactivatedUsersByUnitId[unitId] = true;
    });
    let more = true;
    let page = 1;
    const arr: User[] = [];
    while (more) {
      const searchParams = new URLSearchParams();
      searchParams.append('page', '' + page);
      searchParams.append('limit', '' + FETCH_USERS_BY_UNIT_ID_LIMIT);
      searchParams.append('sort', `firstName,ASC`);

      const search: Record<string, any> = {
        $or: [
          {
            $and: [{'userToUnits.deactivatedAt': {$notnull: 1}}],
          },
        ],
      };
      searchParams.append('s', JSON.stringify(search));

      try {
        const {
          data: {data, pageCount},
        } = await this.api.get<GetManyResponse<User>>(`units/${unitId}/users?${searchParams.toString()}`);
        arr.push(...data);
        more = (pageCount || 0) > page;
        page += 1;
      } catch (err) {
        console.error(err);
        break;
      }

      runInAction(() => {
        this.allDeactivatedUsersByUnitId[unitId] = arr;
        this.loadingAllDeactivatedUsersByUnitId[unitId] = false;
      });
    }
  }

  async fetchUserUnits(force = false) {
    const now = Date.now();

    if (
      !force &&
      this.userUnits &&
      (!this.fetchUserUnitsLastTimeAt || now - this.fetchUserUnitsLastTimeAt.getTime() < this.maxCacheTime)
    ) {
      return;
    }

    if (this.loadingUserUnits) {
      return;
    }

    runInAction(() => {
      this.loadingUserUnits = true;
    });

    try {
      const url = new URL(ADMIN_API_URL);
      url.pathname = 'user/units';
      const {data} = await this.api.get<Record<string, any>[]>(url.toString());

      runInAction(() => {
        this.userUnits = data.filter(({disabledAt}) => !disabledAt);
        this.loadingUserUnits = false;
        this.errorFetchUserUnits = false;
        this.fetchUserUnitsLastTimeAt = new Date();
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      runInAction(() => {
        this.loadingUserUnits = false;
        this.errorFetchUserUnits = true;
      });
    }
  }

  async checkAccessRequest(force = false) {
    const {loading, data} = this.alreadyCreatedAccessRequest;

    if (loading) {
      return;
    }

    if (!force && data) {
      return data;
    }

    runInAction(() => {
      this.alreadyCreatedAccessRequest = {
        loading: true,
      };
    });

    let result;
    try {
      const url = new URL(ADMIN_API_URL);
      url.pathname = 'access-requests/check';
      const {data} = await this.api.post<{result: boolean}>(url.toString());

      result = data;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      runInAction(() => {
        this.alreadyCreatedAccessRequest = {
          loading: false,
          error: true,
        };
      });

      return;
    }

    runInAction(() => {
      this.alreadyCreatedAccessRequest = {
        loading: false,
        error: false,
        data: result,
      };
    });

    return result;
  }

  createAccessRequest = async (data: Partial<AccessRequest>) => {
    try {
      const url = new URL(ADMIN_API_URL);
      url.pathname = 'access-requests';
      const response = await this.api.post<AccessRequest>(url.toString(), data);

      runInAction(() => {
        this.alreadyCreatedAccessRequest = {
          loading: false,
          error: false,
          data: {result: false},
        };
      });

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return null;
    }
  };
}
