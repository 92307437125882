import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Toolbar from '@mui/material/Toolbar';
import React, {memo} from 'react';

import {LoggedProps} from '../../interfaces/logged-props.interface';
import {LOCALES} from '../../utils/constants';
import {DashboardButtonComponent} from '../buttons/dashboard-button.component';
import {LoginButtonComponent} from '../buttons/login-button.component';
import {NoUnitsIconComponent} from '../icons/no-units-icon.component';
import {LocaleSwitcherComponent} from '../locale-switcher/locale-switcher.component';

export interface HeaderProps extends LoggedProps {
  open?: boolean;
  onChangeLanguage?: (value: LOCALES) => void;
  showWithoutUnitsWarning?: boolean;
  onClickNoUnitsIcon?: () => void;
}

function Header({
  open,
  loadingLogin,
  logged,
  onChangeLanguage,
  showWithoutUnitsWarning,
  onClickNoUnitsIcon,
}: HeaderProps) {
  return (
    <Box hidden={!open}>
      <AppBar
        elevation={0}
        position="relative"
        sx={theme => ({
          zIndex: theme.zIndex.drawer + 1,
          background: theme.palette.secondary.light,
        })}
      >
        <Toolbar
          sx={{
            minHeight: '36px !important',
            marginLeft: 'auto',
          }}
        >
          {showWithoutUnitsWarning && <NoUnitsIconComponent onClick={onClickNoUnitsIcon} />}
          {logged && <DashboardButtonComponent />}
          {!loadingLogin ? (
            <LoginButtonComponent showWarningBeforeLogOut isLogged={logged} />
          ) : (
            <Skeleton
              width={'120px'}
              sx={{
                bgcolor: 'secondary.light',
              }}
            />
          )}
          <LocaleSwitcherComponent onChange={onChangeLanguage} />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export const HeaderComponent = memo(Header);
